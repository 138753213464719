<template>
  <tr :class="odd ? 'odd' : 'even'">
    <td class="text-center">
      {{ dataHora }}
    </td>

    <td class="text-left">
      {{ descricao }}
    </td>

    <td class="text-left">
      {{ ligacao }}
    </td>

    <td class="text-left">
      {{ statusName }}
    </td>

    <td class="text-left">
      {{ permissao }}
    </td>

    <td style="width: 120px">
      <v-btn
        class="ml-0 mr-1 my-1"
        color="green darken-4"
        outlined
        x-small
        @click="viewPage(id, descricao)"
      >
        <v-icon small>
          mdi-eye
        </v-icon>
      </v-btn>

      <v-btn
        class="ml-0 mr-1 my-1"
        color="green darken-4"
        outlined
        x-small
        @click="handleCopy(id, descricao)"
      >
        <v-icon small>
          mdi-content-copy
        </v-icon>
      </v-btn>

      <v-btn
        class="ml-0 mr-1 my-1"
        outlined
        x-small
        color="primary"
        @click="editLearnPage(id)"
      >
        <v-icon small>
          mdi-pencil
        </v-icon>
      </v-btn>

      <template v-if="isAdmin">
        <FieldDialogRemove
          btn-text=""
          @remove="removeLearnPage(id)"
          :message="`<b>Tem certeza que deseja excluir esse registro?</b><br><br>${descricao}`"
        />
      </template>
    </td>
  </tr>
</template>

<script>
import slug from 'slug'
import moment from 'moment-timezone'
import { getSession } from '@/utils'
import FieldDialogRemove from '@/components/fields/FieldDialogRemove'
import { ROUTE_LEARN_PAGES_EDIT, ROUTE_LEARN_PAGES_VIEW } from '@/constants'
import copy from 'copy-to-clipboard'
import { getHostAPI } from '@/plugins/Amplify'

export default {
  components: {
    FieldDialogRemove
  },

  props: {
    odd: {},

    id: {},
    status: {},
    descricao: {},
    ligacao: {},
    permissao: {},
    updatedAt: {}
  },

  data () {
    const isAdmin = false
    return { isAdmin }
  },

  mounted () {
    const vm = this
    vm.$nextTick().then(() => {
      getSession().then((session) => {
        vm.isAdmin = session.administrador || session.learnPageProfile === 'reviewer'
      })
    })
  },

  computed: {
    dataHora () {
      return moment.unix(this.updatedAt).format('DD/MM/YY HH:mm')
    },

    statusName () {
      return statuses[this.status]
    }
  },

  methods: {
    removeLearnPage (id) {
      console.log({ id })
      this.$store.dispatch('removeLearnPage', id)
    },

    editLearnPage (id) {
      this.$router.push({ name: ROUTE_LEARN_PAGES_EDIT, params: { id } })
    },

    viewPage (id, descricao) {
      this.$router.push({ name: ROUTE_LEARN_PAGES_VIEW, params: { id, descricao: toSlug(descricao) } })
    },

    handleCopy (id, descricao) {
      const url = getHostAPI().replace('https://api.', 'https://')
      copy(url.concat(`/estudos-pesquisas/visualizar/${id}/${toSlug(descricao)}`).replace('/v1/', '/'))
    }
  }
}

const toSlug = (str) => (
  slug(str || '', '-')
    .replace(/[ª/\\]/g, '-')
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .trim()
)

const statuses = {
  CREATION: 'Criação',
  ARTWORK: 'Em preparação',
  REVIEW: 'Em revisão',
  APPROVED: 'Aprovado'
}
</script>

<style scoped>
  tr.odd>td {
    background-color: #0094d90f
  }
</style>
