<template>
  <v-autocomplete
    :items="learnPages"
    :label="i18n(label)"
    :loading="loading"
    :dense="outlined"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :filter="() => true"
    :search-input.sync="search"
    :hide-details="hideDetails"
    :no-data-text="'filter.sync'.translate()"
    v-model="learnPage"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { requests } from '@/plugins/Amplify'
const onlyNumber = (str) => isNaN(str) ? null : Number(str)
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    ownerId: {},
    label: { default: 'field.learnPage' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false },
    clearable: { default: false },
    hideDetails: { default: true }
  },

  data () {
    return {
      learnPages: [],
      search: null,
      loading: false,
      timeout: null,
      learnPage: onlyNumber(this.value)
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    },

    loadLearnPages (params) {
      const vm = this
      vm.loading = true

      vm.timeout = setTimeout(async () => {
        try {
          const data = await requests.getLearnPages(params).then((res) => res.rows || [])
          vm.learnPages = data.filter(({ id }) => {
            return id !== vm.ownerId
          }).map(({ id, descricao }) => ({ value: id, text: descricao }))
        } catch (error) {
          console.error(error)
        }

        vm.loading = false
      }, 275)
    }
  },

  mounted () {
    if (this.value) this.loadLearnPages({ id: this.value })
  },

  watch: {
    learnPage () {
      const learnPage = onlyNumber(this.learnPage)
      const changed = learnPage !== onlyNumber(this.value)
      if (changed) this.$emit('input', learnPage)
    },

    value () {
      const vm = this
      const value = onlyNumber(vm.value)
      if (value === onlyNumber(vm.learnPage)) return

      vm.learnPage = value
      vm.loadLearnPages({ id: value })
    },

    search () {
      const vm = this

      vm.clearTimeout()

      const descricao = onlyString(vm.search)
      if (!descricao || vm.learnPages.find(({ text }) => text === descricao)) {
        vm.loading = false
        return
      }

      vm.loadLearnPages({ descricao })
    }
  }
}
</script>
