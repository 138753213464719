<template>
  <v-card
    class="elevation-0 my-custom-tabs"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-school-outline
      </v-icon>
      <b>{{ 'title.listLearn'.translate() }}</b>

      <v-spacer />

      <v-btn
        v-if="canCreate"
        x-small
        outlined
        color="white"
        @click="handleNewLearnPage"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Adicionar
      </v-btn>
    </HeaderCard>

    <v-card-text style="padding: 0;">
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          fluid
          class="px-8 pb-8"
        >
          <v-row>
            <v-col cols="6">
              <FilterText
                label="filter.learnDescriptionArg"
                v-model="filters.descricao"
              />
            </v-col>

            <v-col cols="6">
              <FilterText
                label="filter.learnSearchArg"
                v-model="filters.busca"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <FieldLearnPage
                :clearable="true"
                label="filter.learnParentArg"
                v-model="filters.ligacao"
              />
            </v-col>

            <v-col cols="6">
              <FieldPermissao
                :clearable="true"
                label="Permissão"
                v-model="filters.permissao"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <div
        class="tabs"
        v-if="tab"
      >
        <v-tabs
          v-model="tab"
          grow
          hide-slider
          active-class="active-tab"
          bg-color="primary"
          v-if="canShowTabs"
        >
          <v-tab tab-value="creation">
            Em criação
          </v-tab>

          <v-tab tab-value="artwork">
            Em preparação
          </v-tab>

          <v-tab tab-value="review">
            Em revisão
          </v-tab>

          <v-tab tab-value="approved">
            Aprovados
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-data-table
          :headers="headers"
          :items="rows"
          :loading="isLoading"

          :options.sync="options"
          :server-items-length="count"
          :mobile-breakpoint="0"

          disable-pagination
          hide-default-footer

          class="elevation-0"
          item-key="id"
        >
          <template #item="{ item, index }">
            <LearnPageSearchItem
              :id="item.id"
              :descricao="item.descricao"
              :status="item.status"
              :ligacao="item.ligacao"
              :permissao="item.permissao"
              :updated-at="item.updated_at"
              :odd="index % 2"
            />
          </template>

          <template #footer>
            <v-divider class="my-2" />

            <v-pagination
              v-model="page"
              :length="Math.ceil(count / 12)"
              :total-visible="7"
              v-if="Math.ceil(count / 12) > 1"
              @input="$vuetify.goTo(0, { duration: 850, offset: 0, easing: 'easeInOutCubic' })"
            />
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import HeaderCard from '@/components/HeaderCard'
import { getSession } from '@/utils'
import FilterText from '@/components/filters/FilterText'
import LearnPageSearchItem from './LearnPageSearchItem'
import { mapState } from 'vuex'
import FieldLearnPage from '@/components/fields/FieldLearnPage'
import FieldPermissao from '@/components/fields/FieldPermissao'

import {
  STATUS_LOADING,
  ROUTE_LEARN_PAGES_NEW
} from '@/constants'

export default {
  components: {
    FilterText,
    HeaderCard,
    FieldLearnPage,
    FieldPermissao,
    LearnPageSearchItem
  },

  data () {
    const qry = { ...this.$route.query }
    const page = isNaN(qry.page) ? 1 : Number(qry.page)
    const sortBy = hasQryVal(qry, 'sortBy') ? qry.sortBy : 'descricao'
    const orderDesc = String(qry.order || '').trim() === 'DESC'

    const filters = {}
    if (hasQryVal(qry, 'ligacao')) filters.ligacao = qry.ligacao
    if (hasQryVal(qry, 'busca')) filters.busca = qry.busca
    if (hasQryVal(qry, 'descricao')) filters.descricao = qry.descricao
    if (hasQryVal(qry, 'status')) filters.status = qry.status
    if (hasQryVal(qry, 'permissao')) filters.permissao = qry.permissao

    const tab = null

    const options = {
      sortBy: [sortBy],
      sortDesc: [orderDesc],
      itemsPerPage: 12
    }

    return {
      tab,
      page,
      session: {},
      options,
      filters
    }
  },

  mounted () {
    const vm = this
    vm.$nextTick().then(() => {
      getSession().then((session) => {
        const status = getStatusByProfile(session)
        vm.session = session
        vm.filters.status = status
        vm.tab = status
      })
    })
  },

  computed: {
    ...mapState({
      rows: ({ learnPages }) => learnPages.rows,
      count: ({ learnPages }) => learnPages.count,
      isLoading: ({ learnPages }) => learnPages.status === STATUS_LOADING
    }),

    canShowTabs () {
      const session = this.session
      return session.administrador || ['reviewer', 'design'].includes(session.learnPageProfile)
    },

    canCreate () {
      const session = this.session
      return session.administrador || session.learnPageProfile === 'reviewer'
    },

    headers () {
      return [
        { text: 'Data/Hora', align: 'center', sortable: true, value: 'updated_at', width: '150px' },
        { text: 'Descrição', align: 'start', sortable: true, value: 'descricao' },
        { text: 'Ligação', align: '', sortable: false, value: 'status' },
        { text: 'Status', align: '', sortable: false, value: 'status' },
        { text: 'Permissão', align: '', sortable: false, value: 'permissao' },
        { text: '', align: 'center', sortable: false, value: '', width: '120px' }
      ]
    }
  },

  methods: {
    search () {
      const vm = this

      const query = {
        page: vm.page,
        order: vm.options.sortDesc[0] !== false ? 'DESC' : 'ASC',
        sortBy: vm.options.sortBy.length ? vm.options.sortBy[0] : 'descricao'
      }

      const filters = Object.assign({}, vm.filters)
      if (hasQryVal(filters, 'busca')) query.busca = filters.busca
      if (hasQryVal(filters, 'ligacao')) query.ligacao = filters.ligacao
      if (hasQryVal(filters, 'descricao')) query.descricao = filters.descricao
      if (hasQryVal(filters, 'status')) query.status = filters.status
      if (hasQryVal(filters, 'permissao')) query.permissao = filters.permissao
      query.status = vm.tab

      vm.$router.replace({ query }).catch(() => ({}))
      vm.$store.dispatch('searchLearnPages', query)
    },

    handleNewLearnPage () {
      this.$router.push({ name: ROUTE_LEARN_PAGES_NEW })
    }
  },

  watch: {
    tab () {
      this.page = 1
      this.search()
    },

    page () {
      this.search()
    },

    filters: {
      deep: true,
      handler () {
        this.page = 1
        this.search()
      }
    },

    options: {
      deep: true,
      handler () {
        if (!this.filters.status) return
        this.search()
      }
    }
  }
}

const hasQryVal = (qry, val) => Boolean(String(qry[val] || '').trim())

const getStatusByProfile = (session) => {
  if (session.learnPageProfile === 'design') return 'artwork'
  if (session.learnPageProfile === 'reviewer') return 'review'
  return 'approved'
}

</script>
