<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-0 my-1"
        outlined
        :x-small="!btnText"
        :small="!!btnText"
        :block="!!btnText"
        color="red"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          mdi-delete
        </v-icon><span v-if="btnText">&nbsp;&nbsp;{{ btnText }}</span>
      </v-btn>
    </template>

    <v-card dense>
      <v-card-title class="pa-0">
        <v-toolbar
          dark
          dense
          color="red darken-2"
        >
          <v-toolbar-title>
            Exclusão
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="text-center mt-7">
        <div v-html="message" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red"
          dark
          text
          @click="handleRemove"
        >
          Sim, quero Excluir!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    btnText: {
      default: 'Excluir'
    },

    message: {
      default: 'Tem certeza que deseja Excluir?'
    }
  },

  data () {
    return {
      dialog: false
    }
  },
  methods: {
    handleRemove () {
      this.$emit('remove')
      this.dialog = false
    }
  }
}

</script>
